import * as React from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';
import GIF from "../../src/gif/rise.gif";

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const PopupBody = styled('div')(
  ({ theme }) => `
    width: max-content;
    padding: 50px;
    margin: 8px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background-color: rgb(152, 154, 252); // Cambia il colore di sfondo qui
    box-shadow: ${
      theme.palette.mode === 'dark'
        ? `0px 4px 8px rgba(0, 0, 0, 0.7)`
        : `0px 4px 8px rgba(0, 0, 0, 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.25rem; // Impostazione del valore corretto per la dimensione del carattere
    z-index: 1;
    word-wrap: break-word;
  `,
);

const Button = styled('button')(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem; // Impostazione del valore corretto per la dimensione del carattere
    line-height: 1.5;
    background-color: rgb(152, 154, 252); // Cambia il colore di sfondo qui
    padding: 6px 6px; // Aumenta il padding per il button
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid ${blue[500]};
    box-shadow: 0 2px 1px ${
      theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(45, 45, 60, 0.2)'
    }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

    &:hover {
      background-color: rgb(152, 154, 252); // Cambia il colore di sfondo al passaggio del mouse
    }

    &:active {
      background-color: rgb(152, 154, 252); // Cambia il colore di sfondo al click
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
      box-shadow: none;
      &:hover {
        background-color: rgb(152, 154, 252); // Cambia il colore di sfondo al passaggio del mouse
      }
    }
  `,
);

export default function SimplePopup() {
  const [anchor, setAnchor] = React.useState(null);
  const randomTexts = [
    'Posso anche allenarmi per te. Prova a dire: «Caterina, puoi allenarmi per me?»',
    'Posso anche ballare per te. Prova a dire: «Caterina, puoi ballare per me?»',
    'Posso anche salutare per te. Prova a dire: «Caterina, puoi salutarmi?»',
  ];

  const [randomIndex, setRandomIndex] = React.useState(0);

  const handleClick = (event) => {
    if (anchor) {
      // Chiudi il popup e reimposta l'indice del testo casuale
      setAnchor(null);
      setRandomIndex(0);
    } else {
      // Apri il popup e scegli casualmente un testo
      const newIndex = Math.floor(Math.random() * randomTexts.length);
      setAnchor(event.currentTarget);
      setRandomIndex(newIndex);
    }
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div style={{ position: 'relative', right: '-40px', top: '150px' }}>
      <Button aria-describedby={id} type="button" onClick={handleClick}>
        <img
          src={GIF}
          alt="Icona Toggle Popup"
          style={{ width: '60px', height: '60px' }} // Stili per dimensioni immagine
        />
      </Button>
      <BasePopup id={id} open={open} anchor={anchor}>
        <PopupBody>{open && <> {randomTexts[randomIndex].split('\n').map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}</>}</PopupBody>
      </BasePopup>
    </div>
  );
}
