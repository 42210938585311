import { useRef, useEffect, useState } from "react";
import { useChat } from "../hooks/useChat";
import Button from "@mui/material/Button";
import { useTypeContext } from "../hooks/useType";
import PopChat from "./Chat";
import { Widget, addResponseMessage, addUserMessage } from "react-chat-widget";
import NestedModal from "./NestedModal";
import SnackbarContent from "@mui/material/SnackbarContent";
import LongTextSnackbar from "./SnackBar";
import LeftSnackbar from "./LeftSnackBar";
import SimplePopup from "./SimplePopup";
import SwipeableTextMobileStepper from "./SwipeableTextMobileStepper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularIndeterminate from "./CircularIndeterminate";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import LaptopIcon from "@mui/icons-material/Laptop";
import Logo from "../rtbh_logo_white.png";
import NewLogo from "../rtbh_logo_white.png";
import Logo2 from "../Immagine1.png";
import Logo3 from "../Immagine8.svg";
import Logo4 from "../Immagine6.svg";
import Logo5 from "../Immagine7.svg";
import Background from "../Background.jpg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Delete from "../icons8-delete (1).svg";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Sidebar,
  ConversationHeader,
  TypingIndicator,
  MessageSeparator,
  ConversationList,
  Conversation,
  Avatar,
  ArrowButton,
  AddUserButton,
  InfoButton,
  VoiceCallButton,
  VideoCallButton,
  AvatarGroup,
} from "@chatscope/chat-ui-kit-react";
import SimpleBackdrop from "./SimpleBackdrop";
import Avatar2 from "@mui/material/Avatar";
import { Canvas } from "@react-three/fiber";
import { Experience } from "./Experience";
import RecIcon from "@mui/icons-material/Mic";
import InfoIcon from "@mui/icons-material/Info";
import ThreeDRotation from "@mui/icons-material/ThreeDRotation";
import AlertDialogSlide from "./AlertDialogSlide";
import SimpleLineChart from "./SimpleLineChart";
import { bookApi } from "../utils/bookApi";
import { useAuth } from "../utils/AuthContext";
import { Navigate } from "react-router-dom";
import { Search } from "semantic-ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import "../App.css";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useMediaQuery, useTheme } from "@mui/material";

import {
  Add,
  AddAlarm,
  AddAPhoto,
  AddBox,
  AddBoxOutlined,
} from "@mui/icons-material";
import { blue, deepPurple, red } from "@mui/material/colors";

export const UI = ({ ...props }) => {
  const input = useRef();
  const { type, setType } = useTypeContext();
  const {
    chat,
    loading,
    cameraZoomed,
    setCameraZoomed,
    message,
    setMessage,
    messageCount,
  } = useChat();
  const recognitionRef = useRef(null);
  const [showChatWidget, setShowChatWidget] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [text, setText] = useState("");
  const [mex, setMex] = useState([]);
  const [messages, setMessages] = useState("");
  const [open, setOpen] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [rec, setRec] = useState(false);
  const [series, setSeries] = useState([]);
  const [idSession, setIdSession] = useState(() => {
    const savedSession = localStorage.getItem("idSession");
    return savedSession ? parseInt(savedSession, 10) : 1;
  });
  const Auth = useAuth();
  const { getUser, userIsAuthenticated, userLogout } = useAuth();
  const isLoggedIn = Auth.userIsAuthenticated();
  const user = Auth.getUser();
  const [conversations, setConversations] = useState([]);
  const [category, setCategory] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [enabledInput, setEnabledInput] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null); // Gestisce l'apertura del menu
  const [isEditing, setIsEditing] = useState(false); // Gestisce lo stato di editing
  const [title, setTitle] = useState(""); // S
  const [isRenaming, setIsRenaming] = useState(false);
  const textFieldRef = useRef({});
  const [editingConversation, setEditingConversation] = useState(null); // Stato per la conversazione in editing
  const [selectedConversationUid, setSelectedConversationUid] = useState(null);
  const [focusedConversation, setFocusedConversation] = useState(null);
  const [pendingRenaming, SetPendingRenaming] = useState(false);
  const [opens, setOpens] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let timer;
    if (hovering) {
      timer = setTimeout(() => {
        setOpens(true);
      }, 2000); // Delay di 1 secondo
    } else {
      setOpens(false);
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [hovering]);

  const handleMouseEnter = (id) => {
    setTimeout(() => {
      setOpenTooltip(id);
      setOpens(true);
      // Apre il Tooltip solo per l'elemento con questo id
    }, 1000); // Mostra il Tooltip dopo 1 secondo di hovering
  };

  const handleMouseLeave = () => {
    setOpenTooltip(null);
    setOpens(false); // Chiude il Tooltip quando si esce dal mouse
  };

  function removeCitationsFromStream(inputStream) {
    let insideCitation = false;
    let insideBold = false;
    let insideItalic = false;
    let result = "";
    let boldBuffer = "";
    let italicBuffer = "";
    let titleBuffer = "";
    let inTitle = false;

    for (let i = 0; i < inputStream.length; i++) {
      let char = inputStream[i];

      // Gestione delle citazioni
      if (char === "【") {
        insideCitation = true;
      } else if (char === "】") {
        insideCitation = false;
      }
      // Gestione del grassetto
      else if (char === "*" && inputStream[i + 1] === "*") {
        if (insideBold) {
          result += `<strong>${boldBuffer}</strong>`;
          boldBuffer = "";
        }
        insideBold = !insideBold;
        i++; // Salta il secondo asterisco
      }
      // // Gestione del corsivo
      // else if (char === '"') {
      //   if (insideItalic) {
      //     result += `<em>${italicBuffer}</em>`;
      //     italicBuffer = "";
      //   }
      //   insideItalic = !insideItalic;
      // }
      // Gestione dei titoli indicati da ### fino al ritorno a capo
      else if (inputStream.substring(i, i + 4) === "### ") {
        if (inTitle) {
          // Aggiungi l'ultimo titolo se presente
          result += `<h3>${titleBuffer.trim()}</h3>`;
          titleBuffer = "";
        }
        inTitle = true;
        i += 3; // Salta "### "
        titleBuffer = ""; // Inizializza il buffer del titolo
      } else if (inTitle && (char === "\n" || i === inputStream.length - 1)) {
        // Aggiungi il titolo trovato
        if (char === "\n" || i === inputStream.length - 1) {
          if (i === inputStream.length - 1 && char !== "\n") {
            titleBuffer += char; // Aggiungi l'ultimo carattere se è la fine del testo
          }
          result += `<h3>${titleBuffer.trim()}</h3>`;
          titleBuffer = "";
          inTitle = false;
        }
      }
      // Costruzione del testo risultante
      else {
        if (!insideCitation) {
          if (insideBold) {
            boldBuffer += char;
          } else if (insideItalic) {
            italicBuffer += char;
          } else if (inTitle) {
            titleBuffer += char;
          } else {
            result += char;
          }
        }
      }
    }

    // Aggiungi l'ultimo titolo se necessario
    if (inTitle) {
      result += `<h3>${titleBuffer.trim()}</h3>`;
    }

    console.log(result);
    return result;
  }

  const transformAndSetMessages = (data) => {
    const transformedArray = data.flatMap((obj) => [
      {
        props: {
          model: {
            message: removeCitation(obj.title),
            sentTime: "Just now",
            sender: "Assistant",
            direction: "outgoing",
            position: "single",
          },
        },
      },
      {
        props: {
          model: {
            message: removeCitation(obj.input),
            sentTime: "Just now",
            sender: "Assistant",
            direction: "incoming",
            position: "single",
          },
          children: <Avatar name="Joe" src={NewLogo} />,
        },
      },
    ]);
    setMex(transformedArray);
  };

  async function handleAddQuery(mex) {
    const now = new Date();
    const formattedTime = now.toLocaleString();

    const query = {
      title: mex,
      input: messages,
      usermail: user.name,
      conversationUid: idSession,
    };

    await bookApi.addBook(user, query);

    const query2 = {
      topic: user.name,
      title: messages,
      category: category,
      timestamp: formattedTime,
      conversationUid: idSession,
      id_assistant: 1,
      enabled: true,
    };
    await bookApi.addConversation(user, query2);
  }

  async function handleAddConversation() {
    const now = new Date();
    const formattedTime = now.toLocaleString();

    const query = {
      topic: user.name,
      timestamp: formattedTime,
      conversation_uid: idSession,
    };
    await bookApi.addConversation(user, query);
  }

  function removeCitation(text) {
    // Rimuove le citazioni
    let cleanedText = text.replace(/【.*?†.*?】/g, "").trim();

    // Aggiunge il grassetto al testo racchiuso tra ** **
    cleanedText = cleanedText.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    // Aggiunge il corsivo al testo racchiuso tra " "
    cleanedText = cleanedText.replace(/"(.*?)"/g, "<em>$1</em>");

    cleanedText = cleanedText.replace(/### (.*?)(?=\n|$)/g, "<h3>$1</h3>");

    return cleanedText;
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    handleGetConversations();
    localStorage.setItem("idSession", idSession);
    console.log(message);

    const storedConversations = localStorage.getItem("conversations");
    // if (storedConversations) {
    //   setConversations(JSON.parse(storedConversations));
    // }
  }, [category, idSession]);

  useEffect(() => {
    console.log(focusedConversation);
  }, [focusedConversation]);

  const handleSearchBook = async (conversation_UID, category) => {
    try {
      const response = await bookApi.getBooks(user, conversation_UID);
      const messages = response.data;
      if(isMobile){
        setSidebar(false)
      }
      setFocusedConversation(conversation_UID);
      transformAndSetMessages(messages);
      setEnabled(true);
      setIsRenaming(false);
      setEnabledInput(false);
      setCategory(category);
    } catch (error) {}
  };

  const handleGetConversations = async () => {
    try {
      const response =
        category != ""
          ? await bookApi.getConversations(user, user.name, category)
          : await bookApi.getConversations(user, user.name);
      const conversations = response.data;

      // Use a Set to store unique conversation_uid
      const uniqueConversations = [];
      const conversationUidSet = new Set();

      conversations.forEach((conversation) => {
        if (!conversationUidSet.has(conversation.conversation_uid)) {
          uniqueConversations.push(conversation);
          conversationUidSet.add(conversation.conversation_uid);
        }
      });

      setConversations(uniqueConversations);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (editingConversation) {
      const ref = textFieldRef.current[editingConversation.conversation_uid];
      if (ref) {
        ref.focus();
      }
    }
  }, [editingConversation]);

  useEffect(() => {
    if (message.length !== 0) {
      const newMessageObject = {
        props: {
          model: {
            message: removeCitation(message),
            sentTime: "Just now",
            sender: "Assistant",
            direction: "incoming",
            position: "single",
            type: "custom",
          },
          children: (
            <Avatar2
              sx={{
                backgroundImage:
                  "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
              }}
              as={Avatar}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: "80%",
                  height: "80%",
                }}
              />
            </Avatar2>
          ),
        },
      };
      setMex([...mex, newMessageObject]);
      setMessage([]);
      handleAddQuery(message);
      localStorage.setItem("conversations", JSON.stringify(mex));
      addResponseMessage(String(message));
    }
  }, [messageCount]);

  useEffect(() => {
    if (rec) {
      setRec(true);
      initSpeechRecognition();
    }
  }, [message, rec]);

  const handleTextFieldClick = (conversation) => {
    const ref = textFieldRef.current[conversation.conversation_uid];
    if (ref) {
      ref.focus();
    }
  };

  useEffect(() => {
    // Controlla se è necessario riavviare il riconoscimento dopo la chat
    if (!loading && message && recognitionRef.current) {
      recognitionRef.current.start();
    }
  }, [loading, message]);

  const sendMessage = () => {
    const text = input.current.value;
    if (!loading && !message) {
      chat(text, type);
      input.current.value = "";
    }
  };

  const initSpeechRecognition = () => {
    console.log("clicked microphone");
    let SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.error("SpeechRecognition API not supported");
      return;
    }
    let recognition = new SpeechRecognition();
    recognition.onstart = () => {
      console.log("starting listening, speak in microphone");
    };
    recognition.onspeechend = () => {
      console.log("stopped listening");
      recognition.stop();
      setRec(false);
      // setTimeout(() => {
      //   recognition.start();
      // }, 500);
    };
    recognition.onresult = (result) => {
      let transcript = result.results[0][0].transcript;

      const newMessageObject = {
        props: {
          model: {
            message: transcript,
            sentTime: "Just now",
            sender: "User",
            direction: "outgoing",
            position: "single",
          },
          // children: (
          //   <Avatar sx={{ bgcolor: deepPurple[500] }}>
          //     {user.name.slice(0, 2)}
          //   </Avatar>
          // ),
        },
      };

      addUserMessage(transcript);
      setMessages(transcript);
      //sendMessages(transcript);
      setMex([...mex, newMessageObject]);
      chat(transcript, type);
      if (transcript.toLowerCase().includes("open")) {
        setOpen(true);
      }
      if (transcript.toLowerCase().includes("close")) {
        setOpen(false);
      }
    };
    recognition.start();
  };

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
    chat(newMessage, type);
  };

  function sendMessages(event) {
    console.log(`New message incoming! ${inputValue}`);
    const newMessageObject = {
      props: {
        model: {
          message: inputValue, // Il testo del nuovo messaggio
          sentTime: "Just now", // Possiamo impostare il tempo di invio su "Just now" o ottenere il tempo attuale
          sender: "User", // Possiamo impostare il mittente su "Me" o qualsiasi altro valore che rappresenti l'utente
          direction: "outgoing", // Indica che questo messaggio è inviato dall'utente (outgoing)
          position: "single", // Poiché questo è un singolo messaggio, possiamo impostare la posizione su "single"
        },
      },
    };

    setMex([...mex, newMessageObject]);
    setMessages(inputValue);
    chat(inputValue, user.name);
    setInputValue("");
    console.log("message", message);
  }

  const handleQuickButtonClicked = (data) => {};

  const toggleChatWidget = () => {
    setShowChatWidget(!showChatWidget);
  };

  const handleButtonClick = () => {
    chat("stop");
    setIdSession((prevId) => prevId + 1);
    //handleAddConversation();
    //window.location.reload();
    setMex([]);
    setInputValue("");
    setEnabledInput(true);
  };

  const handleClickSidebar = () => {
    setSidebar((prevState) => !prevState);
  };

  const handleButtonCategory = (category) => {
    chat("laptopz");
    setEnabled(true);
    setEnabledInput(true);
    setCategory(category);
    setIdSession((prevId) => prevId + 1);
    //window.location.reload();
    setMex([]);
  };

  const handleOpenGraph = () => {
    setOpen(true);
  };

  const handleRecClick = () => {
    initSpeechRecognition();
    setRec(true);
  };

  const handleLogout = () => {
    userLogout();
    return;
    <Navigate to={"/"} />;
  };

  if (!isLoggedIn) {
    return <Navigate to={"/"} />;
  }

  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };

  const getInitials = (fullName) => {
    // Separare il nome completo in parti
    const nameParts = fullName.split(" ");

    // Prendere la prima parte come nome e le parti restanti come cognome
    const firstName = nameParts[0];
    const lastNameParts = nameParts.slice(1);

    // Calcolare le iniziali
    let initials = "";

    // Se il cognome è composto da una sola parola
    if (lastNameParts.length === 1) {
      initials = lastNameParts[0].slice(0, 2).toUpperCase();
    } else {
      // Se il cognome è composto da più parole
      initials = lastNameParts
        .map((part) => part[0])
        .join("")
        .toUpperCase();
    }

    return initials;
  };

  const handlePaste = (event) => {
    // Prevenire l'azione di incollaggio predefinita
    event.preventDefault();

    // Ottenere il testo dagli appunti
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    setInputValue((prevValue) => prevValue + pastedText);

    // Aggiornare il valore dell'input con il testo incollato
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDisableConversation = async (conversationUid) => {
    try {
      await bookApi.disableConversation(user, selectedConversationUid);
      // Aggiorna la lista delle conversazioni nel frontend
      setConversations((prevConversations) =>
        prevConversations.map((c) =>
          c.conversation_uid === selectedConversationUid
            ? { ...c, enabled: false }
            : c
        )
      );
      setAnchorEl(false);
    } catch (error) {
      console.error("Failed to disable the conversation", error);
    }
  };

  const handleMenuClick = (event, conversation) => {
    setEditingConversation(conversation);
    setSelectedConversationUid(conversation.conversation_uid);
    console.log("uid", conversation.conversation_uid); // Imposta la conversazione in editing
    setAnchorEl(event.currentTarget);
    console.log(conversation);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    if (setIsRenaming == false) {
      setIsEditing(false);
    }
  };

  const handleRename = () => {
    setIsRenaming(true);
    handleMenuClose(); // Imposta la modalità rinomina su true
  };

  const handleTitleChange = (event, conversation) => {
    setEditingConversation((prevState) => ({
      ...prevState,
      title: event.target.value,
    }));
    SetPendingRenaming(true);
    // Imposta il titolo della conversazione corrente
    conversation.title = event.target.value;
  };

  const handleSaveRename = async () => {
    if (editingConversation) {
      // Salva il titolo modificato
      await bookApi.editConversation(
        user,
        editingConversation.title,
        editingConversation.conversation_uid
      );
      console.log("Titolo modificato:", editingConversation.title);
      setEditingConversation(null);
      SetPendingRenaming(false);
      setSelectedConversationUid(null);
      setIsRenaming(false);
    }
  };

  return (
    <>
      <MainContainer
       style={{
        height: "95vh",
        width: "100vw",
      }}
        onClick={(event) => {
          // event.preventDefault();
          // setIsRenaming(!pendingRenaming?false:true);
        }}
      >
        <Sidebar
          position="left"
          style={{
            minWidth: !sidebar
              ? isMobile
                ? "60px"
                : "80px"
              : isMobile
                ? "90%"
                : "350px",
            maxWidth: !sidebar
              ? isMobile
                ? "60px"
                : "80px"
              : isMobile
                ? "450px"
                : "350px",
            transition: "max-width 0.3s ease-in-out",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ConversationHeader
            style={{ backgroundColor: "white", border: "none" }}
          >
            <ConversationHeader.Content
              style={{
                fontSize: isMobile ? "0.8em" : "1em",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "600",
                marginBottom: "20px",
                marginTop: "10px",
                color: "#012A4A",
                alignItems: !sidebar ? "center" : "",
              }}
            >
              <MenuIcon
                onClick={handleClickSidebar}
                style={{
                  cursor: "pointer",
                  marginBottom: "30px",
                  marginLeft: !sidebar ? "0px" : "15px",
                }}
              ></MenuIcon>
              <Button
                style={{
                  cursor: "pointer",
                  borderRadius: sidebar ? "20px" : "50%", // Arrotonda completamente se la sidebar è chiusa
                  padding: sidebar ? "10px 20px" : "10px 10px", // Cambia la spaziatura in base allo stato della sidebar
                  minWidth: sidebar ? "150px" : "30px", // Larghezza minima diversa in base allo stato della sidebar
                  width: sidebar ? "50%" : "30px", // Larghezza fissa quando è circolare
                  height: "30px", // Altezza del pulsante
                  backgroundColor: "#f0f0f0", // Colore di sfondo
                  color: "#333", // Colore del testo
                  borderColor: "#ccc", // Colore del bordo
                  borderWidth: "2px", // Spessore del bordo
                  textTransform: "none", // Mantiene il testo come scritto
                  fontSize: "12px", // Dimensione del font
                  fontFamily: "Poppins", // Font del testo
                  display: "flex", // Utilizza flexbox per l'allineamento
                  alignItems: "center", // Centra verticalmente
                  justifyContent: sidebar ? "flex-start" : "center",
                  marginBottom: "30px", // Spaziatura tra icona e testo o centraggio
                  border: "none",
                  marginLeft: !sidebar ? "0px" : "0px",
                  transition: "all 0.3s ease-in-out",
                  whiteSpace: "nowrap",
                }}
                variant="outlined"
                startIcon={
                  <AddIcon
                    style={{
                      cursor: "pointer",
                      marginRight: sidebar ? "10px" : "-12px",
                      transition: "margin-right 0.3s ease-in-out",
                    }}
                  />
                } // Margine tra l'icona e il testo
                onClick={handleButtonClick}
                disabled={!enabled}
              >
                {sidebar && (
                  <span
                    style={{
                      cursor: "pointer",
                      flexGrow: 1,
                      textAlign: "right",
                      color: "#012A4A",
                    }}
                  >
                    New Session
                  </span>
                )}
              </Button>
              {sidebar && "Your Conversations"}
            </ConversationHeader.Content>
          </ConversationHeader>
          {sidebar && (
            <>
              <ConversationList style={{}}>
                {conversations
                  .filter((c) => c.enabled == true)
                  .map((c) => (
                    <Tooltip
                      open={!opens ? false : openTooltip === c.conversation_uid}
                      style={{
                        background:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                      }}
                      title={
                        !selectedConversationUid ? (
                          <span
                            style={{
                              fontSize: "1.3em",
                              color: "white",
                            }}
                          >
                            {c.title}
                          </span>
                        ) : (
                          ""
                        )
                      }
                      placement="right"
                      arrow
                      sx={{
                        "& .MuiTooltip-tooltip": {
                          color: "white",
                          borderRadius: "8px",
                          minWidth: "150px", // Imposta una larghezza minima
                          fontSize: "1em", // Dimensione del testo
                        },
                        "& .MuiTooltip-arrow": {
                          color: "rgb(53, 96, 141)",
                        },
                      }}
                    >
                      <Conversation
                        onMouseEnter={() =>
                          handleMouseEnter(c.conversation_uid)
                        }
                        onMouseLeave={handleMouseLeave}
                        onClick={() =>
                          !isRenaming
                            ? handleSearchBook(c.conversation_uid, c.category)
                            : ""
                        }
                        key={c.conversation_uid}
                        style={{
                          fontSize: "1em",
                          fontFamily: "Poppins",
                          textTransform: "none",
                          textAlign: "left",
                          color: "#012A4A",
                          backgroundColor:
                            focusedConversation === c.conversation_uid
                              ? "#f0f0f0"
                              : "", // Evidenziazione
                          border:
                            selectedConversationUid === c.conversation_uid &&
                            isRenaming
                              ? "2px solid #007bff"
                              : "", // Evidenziazione
                        }}
                      >
                        <Conversation.Content style={{ cursor: "pointer" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "15px",
                              textColor: "#012A4A !important",
                            }}
                          >
                            <TextField
                              fullWidth
                              focused
                              autoFocus
                              ref={(el) =>
                                (textFieldRef.current[c.conversation_uid] = el)
                              }
                              inputRef={(el) => {
                                if (el) {
                                  textFieldRef.current[c.conversation_uid] = el;
                                }
                              }}
                              value={
                                editingConversation?.conversation_uid ===
                                c.conversation_uid
                                  ? editingConversation.title
                                  : c.title
                              }
                              onClick={() =>
                                handleTextFieldClick(c.conversation_uid)
                              }
                              onBlur={(e) => {
                                // Verifica se il blur non è causato dal click sul menu

                                handleSaveRename();
                              }}
                              onChange={(e) => handleTitleChange(e, c)}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  handleSaveRename(); // Chiama la funzione per salvare il nuovo titolo
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                readOnly: isRenaming
                                  ? editingConversation?.conversation_uid !==
                                    c.conversation_uid
                                  : true,
                                style: {
                                  padding: "0px",
                                  fontSize: "0.875em",
                                  color: "#012A4A !important",
                                  fontFamily: "Poppins, sans-serif",
                                  disableUnderline: true,
                                  userSelect: "none",
                                  cursor: "pointer",
                                },
                              }}
                              style={{ marginRight: "8px" }}
                            />
                            <div style={{ flexGrow: 1 }}></div>

                            {/* Icona con menu */}
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMenuClick(event, c);
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>

                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={(event) => {
                                event.stopPropagation();
                                handleMenuClose(event, c);
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              sx={{
                                "& .MuiPaper-root": {
                                  borderRadius: "18px",
                                  backgroundColor: "#f5f5f5", // Colore grigio per il dropdown
                                  color: "#012A4A",
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(event) => {
                                  event.stopPropagation(); // Previene la chiamata a handleSearchBook
                                  handleRename(event, c);
                                }}
                              >
                                <ListItemIcon
                                  style={{ color: "rgb(74,94,113)" }}
                                >
                                  <ModeIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Rename" />
                              </MenuItem>
                              <MenuItem
                                onClick={(event) => {
                                  handleDisableConversation(c.conversation_uid);
                                }}
                              >
                                <ListItemIcon
                                  style={{ color: "rgb(74,94,113)" }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Delete" />
                              </MenuItem>
                            </Menu>
                          </div>
                        </Conversation.Content>
                        {/* <DeleteOutlinedIcon
              as={Avatar}
              name="Lilly"
              src={DeleteOutlinedIcon}
              onClick={(e) => {
                e.stopPropagation(); // Impedisce che il click sul bottone propaghi al click della conversazione
                handleDisableConversation(c.conversation_uid);
                }}
                style={{
                  cursor: "pointer",
                  paddingRight: "10px",
                  color: "red",
                  }}
                  ></DeleteOutlinedIcon> */}
                      </Conversation>
                    </Tooltip>
                  ))}
              </ConversationList>
            </>
          )}
        </Sidebar>
        <ChatContainer
          onClick={(event) => {
            event.preventDefault();
            setIsRenaming(false);
            setOpens(false);
          }}
          style={{
            alignContent: "center",
            boxSizing: "border-box", // Assicura che padding e bordi siano inclusi nelle dimensioni totali dell'elemento
            "& > *": {
              flex: "1 1 auto", // Permette agli elementi di adattarsi e di crescere o restringersi in modo proporzionale
              minWidth: "0", // Evita l'overflow orizzontale
              maxWidth: "100%", // Limita la larghezza massima degli elementi al 100% della loro area
              marginBottom: "10px",
              height: "90%", // Aggiunge uno spazio tra gli elementi
            },
          }}
        >
          <ConversationHeader
            style={{
              backgroundColor: "rgb(1, 42, 74)",
              padding: isMobile ? "10px" : "20px",
            }}
          >
            <ConversationHeader.Content>
              <div
                style={{
                  display: "flex",
                  gap: isMobile ? "10px" : "20px",
                }}
              >
                <Avatar
                  onClick={() => window.location.reload()}
                  style={{
                    marginTop: isMobile ? "5px" : "2px",
                    marginBottom: isMobile ? "5px" : "2px",
                    cursor: "pointer",
                  }}
                  name="Zoe"
                  src={NewLogo}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: isMobile ? "0.7em" : "0.9em",
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontWeight: "600",
                      color: "white",
                      marginTop: "3px",
                    }}
                  >
                    Kim
                  </span>
                  <span
                    style={{
                      fontSize: isMobile ? "0.6em" : "0.7em",
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontWeight: "600",
                      color: "white",
                      marginBottom: "3px",
                    }}
                  >
                    Powered by RTBH AI Technology
                  </span>
                </div>
              </div>
            </ConversationHeader.Content>

            {/* <ConversationHeader.Content userName="Kim for RTBH"></ConversationHeader.Content> */}
            {!isMobile || !sidebar ? (
              <ConversationHeader.Actions>
                <div
                  style={{
                    display: "flex",
                    gap: isMobile ? "15px" : "25px",
                    justifyContent: isMobile ? "center" : "flex-end",
                  }}
                >
                  <Box
                    onClick={() => handleButtonCategory("laptop")}
                    sx={{
                      cursor: "pointer",
                      borderRadius: "8px",
                      border: "none",
                      width: isMobile ? "30px" : "35px",
                      height: isMobile ? "30px" : "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LaptopMacIcon
                      style={{
                        color: category == "laptop" ? "#FBC02D" : "lightgray",
                        fontSize: isMobile ? 25 : 30,
                      }}
                    ></LaptopMacIcon>
                  </Box>
                  <Box
                    //onClick={() => handleButtonCategory("tablet")}
                    sx={{
                      borderRadius: "8px", // Arrotonda gli angoli se necessario
                      border: "none", // Aggiunge un contorno nero di 1px
                      width: isMobile ? "30px" : "35px",
                      height: isMobile ? "30px" : "35px", // Imposta l'altezza del Box
                      display: "flex", // Usa flexbox per centrare il contenuto
                      alignItems: "center", // Centra verticalmente
                      justifyContent: "center", // Centra orizzontalmente // Aggiunge un contorno nero di 1px // Arrotonda gli angoli se necessario
                      // backgroundColor:
                      //   category == "tablet" ? "white" : "lightgray",
                      disabled: true,
                    }}
                  >
                    <TabletMacIcon
                      style={{
                        color: category == "tablet" ? "green" : "lightgray",
                        fontSize: isMobile ? 22 : 25,
                      }}
                    ></TabletMacIcon>
                  </Box>
                  <Box
                    //onClick={() => handleButtonCategory("smartphone")}
                    sx={{
                      borderRadius: "8px", // Arrotonda gli angoli se necessario
                      border: "none", // Aggiunge un contorno nero di 1px
                      width: isMobile ? "30px" : "35px",
                      height: isMobile ? "30px" : "35px",
                      display: "flex", // Usa flexbox per centrare il contenuto
                      alignItems: "center", // Centra verticalmente
                      justifyContent: "center", // Centra orizzontalmente // Aggiunge un contorno nero di 1px // Arrotonda gli angoli se necessario
                      // backgroundColor:
                      //   category == "smartphone" ? "white" : "lightgray",
                    }}
                  >
                    {/* <img
                      src={Logo5}
                      alt="Laptop"
                      style={{ width: "50%", height: "50%" }}
                      /> */}
                    <PhoneIphoneIcon
                      style={{
                        color:
                          category == "smartphone" ? "purple" : "lightgray",
                        fontSize: 28,
                      }}
                    ></PhoneIphoneIcon>
                  </Box>
                  {/* <InfoButton /> */}
                  {/* <AlertDialogSlide></AlertDialogSlide> */}

                  <div style={{ marginLeft: isMobile ? "0px" : "20px" }}>
                    <Avatar2
                      onClick={handleClick}
                      sx={{
                        backgroundImage:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                        cursor: "pointer",
                        padding: "2px",
                        marginRight: isMobile ? "5px" : "10px",
                      }}
                    >
                      {getInitials(user.name)}
                      {/* {user.name.slice(0, 2)} */}
                    </Avatar2>
                    <Menu
                      sx={{
                        "& .MuiPaper-root": {
                          backgroundColor: "#f5f5f5", // Colore grigio per il dropdown
                          color: "rgb(74,94,113)",
                          mt: 8,
                        },
                      }}
                      anchorEl2={anchorEl2}
                      open={Boolean(anchorEl2)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.preventDefault();
                          handleLogout();
                        }}
                      >
                        <ListItemIcon style={{ color: "rgb(74,94,113)" }}>
                          <PowerSettingsNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </ConversationHeader.Actions>
            ) : (
              ""
            )}
          </ConversationHeader>
          <MessageList
            style={{
              marginTop: !enabled ? "5%" : "40px",
              alignContent: "center",
              backgroundSize: "cover",
              width: enabled ? (isMobile ? '95%' : '70%') :  (isMobile ? '95%' : '95%'),
              height: "100%",
              marginLeft: enabled ? (isMobile ? '5%' : '15%') :  (isMobile ? '0%' : '0%'),
              backgroundImage: `url(${Background})`,
              zIndex: 0,
            }}
          >
            {enabled ? (
              loading & (message.length > 0) ? (
                <>
                  {mex.map((m, i) => (
                    <Message
                      style={{ backgroundColor: "black ! important" }}
                      key={i}
                      {...m.props}
                    >
                      <Message.HtmlContent
                        html={m.props.model.message}
                      ></Message.HtmlContent>
                    </Message>
                  ))}
                  <Message
                    model={{
                      //message: message,
                      sender: "Joe",
                      sentTime: "just now",
                      direction: "incoming",
                      type: "html",
                    }}
                    style={{ backgroundColor: "black !important" }}
                  >
                    <Avatar2
                      sx={{
                        backgroundImage:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                      }}
                      as={Avatar}
                    >
                      <img
                        src={Logo}
                        alt="Logo"
                        style={{
                          width: "80%",
                          height: "80%",
                        }}
                      />
                    </Avatar2>
                    <Message.HtmlContent
                      html={removeCitationsFromStream(message)}
                    ></Message.HtmlContent>
                  </Message>
                </>
              ) : (
                mex.map((m, i) =>
                  m.type === "separator" ? (
                    <MessageSeparator key={i} {...m.props} />
                  ) : (
                    <Message
                      style={{
                        fontSize: "1em",
                        fontFamily: "Poppins",
                        textTransform: "none",
                      }}
                      key={i}
                      {...m.props}
                    >
                      <Message.HtmlContent
                        html={m.props.model.message}
                      ></Message.HtmlContent>
                      {m.props.model.direction == "incoming" && (
                        <Avatar2
                          sx={{
                            backgroundImage:
                              "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                          }}
                          as={Avatar}
                        >
                          <img
                            src={Logo}
                            alt="Logo"
                            style={{
                              width: "80%",
                              height: "80%",
                            }}
                          />
                        </Avatar2>
                      )}
                    </Message>
                  )
                )
              )
            ) : (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: isMobile ? "90%" : "100%",
                  textAlign: "center",
                  fontSize: "1.2em",
                }}
              >
                <Avatar2
                  sx={{
                    width: "8vmin", // Usa unità relative per dimensioni responsivi
                    height: "8vmin", // Mantieni la stessa dimensione per larghezza e altezza per la circolarità
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%", // Circolarità garantita
                    marginTop: isMobile ? "25px" : "50px",
                    backgroundImage:
                      "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                    backgroundSize: "cover", // Assicura che l'immagine di sfondo copra tutto l'avatar
                    backgroundPosition: "center", // Centro dell'immagine di sfondo
                    overflow: "hidden", // Nasconde eventuali parti dell'immagine che escono dal cerchio
                  }}
                >
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{
                      width: "80%",
                      height: "80%",
                    }}
                  />
                </Avatar2>

                <span
                  style={{
                    fontSize: isMobile ? "1.2em" : "2em",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    marginTop: "30px",
                    marginBottom: "10px",
                    fontWeight: "500",
                    color: "rgb(87,148,229)",
                  }}
                >
                  Hi {user.name}
                </span>
                <span
                  style={{
                    fontSize: isMobile ? "0.7em" : "1em",
                    fontFamily: "Poppins, sans-serif",
                    textTransform: "none",
                    lineHeight: "1.5",
                    color: "#012A4A",
                  }}
                >
                  I'm Kim! I'm the RTBH personal assistant.<br></br> I'm ready
                  to answer your questions
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    height: "100vh",
                    marginTop: isMobile ? "50px" : "100px",
                    gap: isMobile ? "40px" : "60px",
                  }}
                >
                  <Tooltip
                    style={{
                      background:
                        "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                    }}
                    title={
                      <span
                        style={{
                          fontSize: "1.6em",
                          color: "white",
                        }}
                      >
                        Laptops
                      </span>
                    }
                    placement="bottom"
                    arrow
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        color: "white",
                        borderRadius: "8px",
                        padding: "15px 20px",
                        minWidth: "150px", // Imposta una larghezza minima
                        fontSize: "1.5em", // Dimensione del testo
                      },
                      "& .MuiTooltip-arrow": {
                        color: "rgb(53, 96, 141)",
                      },
                    }}
                  >
                    <Box
                      onClick={() => handleButtonCategory("laptop")}
                      sx={{
                        borderRadius: "8px",
                        cursor: "pointer",
                        border:
                          category == "laptop"
                            ? "1px solid #FBC02D"
                            : "1px solid lightgray",
                        width: "10vmin",// Usa unità relative per dimensioni responsivi
                        height:  isMobile ? "10vmin" : "8vmin",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                        transition:
                          "background-color 0.3s ease-in-out, transform 0.3s ease-in-out",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          transform: "scale(1.1)",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Ag
                        },
                      }}
                    >
                      <LaptopMacIcon
                        sx={{
                          color: category == "laptop" ? "#FBC02D" : "lightgray",
                          fontSize: isMobile ? 30 : 35,
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "1.6em",
                          color: "white",
                        }}
                      >
                        Tablets
                      </span>
                    }
                    placement="bottom" // Puoi cambiare la posizione del tooltip se necessario
                    arrow
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        background:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                        color: "white", // Colore del testo
                        fontSize: "2em", // Dimensione del testo
                        borderRadius: "8px",
                        padding: "10px 15px",
                        minWidth: "150px",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "rgb(53, 96, 141)", // Colore della freccia del tooltip
                      },
                    }}
                  >
                    <Box
                      // onClick={() => handleButtonCategory("laptop")}
                      sx={{
                        borderRadius: "8px",
                        border:
                          category == "laptop"
                            ? "1px solid #FBC02D"
                            : "1px solid lightgray",
                        width: "10vmin", // Usa unità relative per dimensioni responsivi
                        height:  isMobile ? "10vmin" : "8vmin",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                        transition:
                          "background-color 0.3s ease-in-out, transform 0.3s ease-in-out", // Aggiunge una transizione per l'illuminazione
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.8)", // Cambia colore al passaggio del mouse
                          transform: "scale(1.1)",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Ag
                        },
                      }}
                    >
                      <TabletMacIcon
                        sx={{
                          color: category == "laptop" ? "#FBC02D" : "lightgray",
                          fontSize: isMobile ? 30 : 35,
                        }}
                      ></TabletMacIcon>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "1.6em",
                          color: "white",
                        }}
                      >
                        Smartphones
                      </span>
                    }
                    placement="bottom" // Puoi cambiare la posizione del tooltip se necessario
                    arrow
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        background:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                        color: "white", // Colore del testo
                        fontSize: "2em", // Dimensione del testo
                        borderRadius: "8px",
                        padding: "10px 15px",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "rgb(53, 96, 141)", // Colore della freccia del tooltip
                      },
                    }}
                  >
                    <Box
                      // onClick={() => handleButtonCategory("laptop")}
                      sx={{
                        borderRadius: "8px",
                        border:
                          category == "laptop"
                            ? "1px solid #FBC02D"
                            : "1px solid lightgray",
                        width:  "10vmin", // Usa unità relative per dimensioni responsivi
                        height: isMobile ? "10vmin" : "8vmin",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage:
                          "linear-gradient(to bottom right, rgb(53, 96, 141) 70%, rgb(65, 63, 113) 85%, rgb(70, 50, 101) 95%, rgb(51, 108, 144) 100%)",
                        transition:
                          "background-color 0.3s ease-in-out, transform 0.3s ease-in-out", // Aggiunge una transizione per l'illuminazione
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.8)", // Cambia colore al passaggio del mouse
                          transform: "scale(1.1)",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Ag
                        },
                      }}
                    >
                      <PhoneIphoneIcon
                        sx={{
                          color: category == "laptop" ? "#FBC02D" : "lightgray",
                          fontSize: isMobile ? 35 : 40,
                        }}
                      ></PhoneIphoneIcon>
                    </Box>
                  </Tooltip>
                </div>
                <span
                  style={{
                    fontSize: isMobile ? "0.6em" : "0.8em",
                    fontFamily: "Poppins",
                    textTransform: "none",
                   
                    position: "relative",
                    color: "#012A4A",
                  }}
                >
                  Kim might provide inaccurate information. Please, consider
                  verifying important answers.
                </span>
              </Box>
            )}
          </MessageList>
          {enabled && (
            <Box
              as={MessageInput}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                marginLeft: "3.5%",
                marginRight:"3.5%",
                marginBottom: isMobile?"40px":"70px",
                backgroundColor: "white", // Colore grigio
                borderRadius: "40px",
                padding: "5px 10px", // Spazio interno del box
              }}
            >
              <TextField
                placeholder="Type message here"
                onPaste={handlePaste}
                onChange={handleInputChange}
                value={inputValue}
                disabled={!enabledInput}
                onSend={sendMessages}
                attachButton={false}
                fullWidth
                onKeyPress={(event) => {
                  if (event.key === "Enter" && inputValue.trim()) {
                    sendMessages();
                    event.preventDefault(); // Prevents the default action (such as a newline in a textarea)
                  }
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    fontFamily: "Poppins, sans-serif",
                    textTransform: "none",
                    borderRadius: "40px",
                    backgroundColor: "#f0f0f0",
                    paddingRight: "30px",
                    width: "100%", // Spazio per i bottoni a destra
                  },
                  endAdornment: (
                    <InputAdornment position="end" style={{ gap: "15px" }}>
                      <Tooltip
                        style={{}}
                        title={
                          <span
                            style={{
                              fontSize: "1em",
                              color: "white",
                            }}
                          >
                            Ask Kim
                          </span>
                        }
                        placement="bottom"
                        arrow
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            color: "white",
                            borderRadius: "8px",
                          },
                          "& .MuiTooltip-arrow": {
                            color: "rgb(53, 96, 141)",
                          },
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setRec(true);
                          }}
                          edge="end"
                          disabled={!enabledInput}
                        >
                          <MicIcon style={{ color: "#012A4A" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        style={{}}
                        title={
                          <span
                            style={{
                              fontSize: "1em",
                              color: "white",
                            }}
                          >
                            Send
                          </span>
                        }
                        placement="bottom"
                        arrow
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            color: "white",
                            borderRadius: "8px",
                          },
                          "& .MuiTooltip-arrow": {
                            color: "rgb(53, 96, 141)",
                          },
                        }}
                      >
                        <IconButton
                          disabled={!enabledInput || !inputValue.trim()}
                          onClick={sendMessages}
                          edge="end"
                        >
                          <SendIcon
                            style={{
                              color: inputValue.trim()
                                ? "#012A4A"
                                : "lightgray",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.8em",
                  fontFamily: "Poppins, sans-serif",
                  textTransform: "none",
                  color: "rgb(74,94,113)",
                  marginTop: isMobile? "20px":"20px",
                  textAlign: "center",
                }}
              >
                Kim might provide inaccurate information. Please, consider
                verifying important answers.
              </Typography>
            </Box>
          )}
        </ChatContainer>
      </MainContainer>
      {/* <h1
          style={{
            top: 10,
            left: 20,
            fontWeight: "Exo SemiBold",
            fontSize: "60px",
            color: "rgb(58,74,106)",
            }}
            >
            Kim
            </h1> */}
      {/* <Widget handleNewUserMessage={handleNewUserMessage}
     resizable
     /> */}

      {/* <LongTextSnackbar onCloseButtonClick={handleSnackbarClose}></LongTextSnackbar> */}
    </>
  );
};
