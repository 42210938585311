import React, { createContext, useContext, useState } from 'react';

// Creazione del Context per lo stato type
const TypeContext = createContext();

// Provider personalizzato che contiene lo stato type e la funzione per modificarlo
export const TypeProvider = ({ children }) => {
  const [type, setType] = useState('Female'); // Valore di default

  return (
    <TypeContext.Provider value={{ type, setType }}>
      {children}
    </TypeContext.Provider>
  );
};

// Hook personalizzato per utilizzare il contesto dello stato type
export const useTypeContext = () => {
  return useContext(TypeContext);
};
