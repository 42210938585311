import axios from 'axios'
import { config } from '../config'

export const bookApi = {
  authenticate,
  signup,
  numberOfUsers,
  numberOfBooks,
  getUsers,
  deleteUser,
  getBooks,
  deleteBook,
  addBook,
  addConversation,
  getConversations,
  disableConversation,
  editConversation
}

function authenticate(username, password) {
  return instance.post('/auth/authenticate', { username, password }, {
    headers: { 'Content-type': 'application/json' }
  })
}

function signup(user) {
  return instance.post('/auth/signup', user, {
    headers: { 'Content-type': 'application/json' }
  })
}

function numberOfUsers() {
  return instance.get('/public/numberOfUsers')
}

function numberOfBooks() {
  return instance.get('/public/numberOfBooks')
}

function getUsers(user, username) {
  const url = username ? `/api/users/${username}` : '/api/users'
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function deleteUser(user, username) {
  return instance.delete(`/api/users/${username}`, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function getBooks(user, text) {
  const url = text ? `/api/books?conversation_uid=${text}` : '/api/books'
  return instance.get(url, {
    headers: { 'Authorization': basicAuth(user) }
  })
}


function addConversation(user, conversation) {
  return instance.post('/api/conversations', conversation, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': basicAuth(user)
    }
  })
}

function getConversations(user, topic, category) {
  const url = category ? `/api/conversations?category=${category}&topic=${topic}` : `/api/conversations?topic=${topic}`
  return instance.get(url, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': basicAuth(user)
    }
  })
}

function deleteBook(user, isbn) {
  return instance.delete(`/api/books/${isbn}`, {
    headers: { 'Authorization': basicAuth(user) }
  })
}

function addBook(user, book) {
  return instance.post('/api/books', book, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': basicAuth(user)
    }
  })
}

function disableConversation(user, conversationUid) {
  return instance.put(`/api/conversations/disable/${conversationUid}`, {}, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': basicAuth(user)
    }
  });
}

function editConversation(user, title, conversationUid) {
  return instance.put(`/api/conversations/edit/${encodeURIComponent(title)}/${conversationUid}`, {}, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': basicAuth(user)
    }
  });
}

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

// -- Helper functions

function basicAuth(user) {
  return `Basic ${user.authdata}`
}