import { createContext, useContext, useEffect, useState } from "react";

const backendUrl = "";
const backendUrl2 = "http://localhost:8080";

let voiceID = "hyYj5OGs35xO4HICEf1s";
const wsUri = `wss://api.elevenlabs.io/v1/text-to-speech/${voiceID}/stream-input?model_id=eleven_multilingual_v2`;
const wsUriAudio = ``;
const ws = new WebSocket(wsUri);
const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [audioQueue, setAudioQueue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cameraZoomed, setCameraZoomed] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false); 
  const [message, setMessage] = useState([]);
  const [lip, setlip] = useState(null);
  const [messageCount, setMessageCount] = useState(0);
  const [messageComp, setMessageComp] = useState([]);
  


// Gestione dei messaggi ricevuti dal server
ws.onmessage = function(event) {
  console.log('Messaggio ricevuto dal server:', event.data);

  // Esegui l'elaborazione del messaggio qui
};

  const playNextAudio = () => {
    if (audioQueue.length > 0 && !isPlaying) {
      const audioUrl = audioQueue[0];
      const audio = new Audio(audioUrl);
      audio.addEventListener('ended', handleAudioEnded);
      audio.play();
      setIsPlaying(true);
    }
  };

  const handleAudioEnded = () => {
    setAudioQueue((prevQueue) => prevQueue.slice(1)); 
    setIsPlaying(false)// Rimuove l'audio appena riprodotto dalla coda
  };

  const chat = async (message, user) => {
    setLoading(true);

    const response = await fetch(`${backendUrl}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, user }),
    })
    // .then(response => response.json())
    // .then(data => {
    //   console.log('Risposta dal server:', data.message);
    //   setMessage(data.message)
    //   setlip(data.lypsinc)
    //   // Usa il valore di data.message come desideri
    // })
    // .catch(error => { 
    //   console.error('Si è verificato un errore:', error);
    // });

    console.log("response", response);
    if (response.ok) {
        
      //throw new Error("Errore nella richiesta al server.");
    }

    let receivedMessage = '';
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();
      while (true) {
        const { value, done } = await reader.read();
        try {
      if (done) break;
      receivedMessage += value
      setMessage((prev) => prev + value)
      console.log("message-stream",message);
      //const audioArrayBuffer = base64ToArrayBuffer(value);
      ////const audioBlob = new Blob([audioArrayBuffer]);
      //const audioUrl = URL.createObjectURL(audioBlob);
      //console.log("audio buono ", value); // Avvia la riproduzione dell'audio se non è già in corso e c'è dell'audio nella coda
      //setAudioQueue((prevQueue) => [...prevQueue, audioUrl]); // Aggiunge l'URL dell'audio alla coda di riproduzione
      
      
    } catch (error) {
      //console.log("audio corrotto ", value); // Avvia la riproduzione dell'audio se non è già in corso e c'è dell'audio nella coda
      console.error("Errore nel decoding:", error);
    }
    
  }
    setMessageComp(receivedMessage) 
    setLoading(false);
    setMessageCount(prevCount => prevCount + 1);
  };

  useEffect(() => {
    if (audioQueue.length > 0) {
      //playNextAudio();
    }
  }, [audioQueue, loading]);

  return (
    <ChatContext.Provider
      value={{
        chat,
        messageCount,
        messageComp,
        message,
        loading,
        lip,
        cameraZoomed,
        setCameraZoomed,
        setMessage
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(decodeURIComponent(base64));
  const length = binaryString.length;
  const bytes = new Uint8Array(length); 
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
