import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useChat } from "../hooks/useChat";
import NestedModal from "./NestedModal";
import { Modal } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "",
    imgPath: "",
  },
  {
    label: "",
    imgPath: "",
  },
  {
    label: "",
    imgPath: "",
  },
  {
    label: "",
    imgPath: "",
  },
];

function SwipeableTextMobileStepper({ image }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const maxSteps = images.length;
  //const backendUrl = "http://localhost:3001";
  const backendUrl = "";
  //const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const { chat, loading, cameraZoomed, setCameraZoomed, message } = useChat();
  const [close, setClose] = React.useState(false);
  // const handleOpen = () => setOpens(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (message && !loading) {
      // Fetch per ottenere le immagini dal backend
      fetch(`${backendUrl}/get_image`) // Assicurati di modificare l'URL se necessario
        .then((response) => {
          console.log(response);

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          // Converte il blob in un URL dell'immagine
          const imageUrl = URL.createObjectURL(blob);
          setImages([...images, imageUrl]);
          // Aggiorna lo stato delle immagini con l'URL dell'immagine ottenuto
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, [message, loading]); // Assicurati di passare un array vuoto come secondo argomento per evitare il loop infinito

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleOpenGraph = (url) => {
    setOpen(true);
    setUrl(url)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  return (
    <>
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
         component="img"
         src={url}
        >
        </Box>
      </Modal>
      <Box sx={{ maxWidth: 600, flexGrow: 1 }}>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 100,
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          <Typography></Typography>
        </Paper>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                onClick={(event) => {
                  event.preventDefault(); // Prevenire il comportamento predefinito dell'evento di clic
                  handleOpenGraph(step); // Chiamare la funzione handleOpenGraph
                }}
                  component="img"
                  sx={{
                    height: 255,
                    display: "block",
                    maxWidth: 400,
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </SwipeableViews>
        <MobileStepper
          steps={10}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
    </>
  );
}

export default SwipeableTextMobileStepper;
