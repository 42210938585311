import logo from "./logo.svg";
import { Leva } from "leva";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Experience } from "./components/Experience";
import { ChatProvider } from "./hooks/useChat";
import { Canvas } from "@react-three/fiber";
import { UI } from "./components/UI";
import FaceLandmarkCanvas from "../src/components/FaceLandmarkCanvas";
import { TypeProvider } from "./hooks/useType";
import { Widget } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import SimplePopup from "./components/SimplePopup";
import { AuthProvider } from "./utils/AuthContext";
import Login from "./components/Login";

const handleNewUserMessage = (newMessage) => {
  console.log(`New message incoming! ${newMessage}`);
  // Now send the message throught the backend API
};

const handleQuickButtonClicked = (data) => {
  console.log(data);
};

function App() {
  return (
    <AuthProvider>
      <ChatProvider>
        <TypeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/userpage" element={<UI></UI>} />
          </Routes>
        </Router>
          <Leva hidden />
        </TypeProvider>
      </ChatProvider>
    </AuthProvider>
  );
}

export default App;
