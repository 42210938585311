import { useAnimations, useFBX, useGLTF } from "@react-three/drei";
import { useFrame, useGraph } from "@react-three/fiber";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SkeletonUtils } from "three-stdlib";
import { useChat } from "../hooks/useChat";

const MOVEMENT_SPEED = 0.032;

export function AvatarCustom({
  hairColor = "green",
  topColor = "pink",
  bottomColor = "brown",
  id,
  ...props
}) {
  const position = (() => props.position, []);
  const avatar = useRef();
  const [path, setPath] = useState();
  const [audio, setAudio] = useState();
  const { message, onMessagePlayed, chat } = useChat();
  const group = useRef();
  const { scene } = useGLTF(props.url);
  // Skinned meshes cannot be re-used in threejs without cloning them
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  // useGraph creates two flat object collections for nodes and materials
  const { nodes } = useGraph(clone);

  //   const { animations } = useGLTF("/models/animations.glb");

  //   const { actions, mixer } = useAnimations(animations, group);
  //   const [animation, setAnimation] = useState(
  //     animations.find((a) => a.name === "Talk") ? "Talk" : animations[0].name // Check if Idle animation exists otherwise use first animation
  //   );

  const { animations: walkAnimation } = useGLTF("/models/M_Walk_001.glb");
  const { animations: TalkAnimation } = useFBX("/models/Talking.fbx");
  const { animations: danceAnimation } = useGLTF("/models/M_Dances_001.glb");
  const { animations: idleAnimation } = useGLTF(
    "/models/M_Standing_Idle_001.glb"
  );

  const { actions } = useAnimations(
    [walkAnimation[0], TalkAnimation[0], idleAnimation[0], danceAnimation[0]],
    avatar
  );
  TalkAnimation[0].name = "Talk";
  const [animation, setAnimation] = useState("M_Standing_Idle_001");

  useEffect(() => {
    console.log(message);
    if (!message) {
      setAnimation("M_Standing_Idle_001");
      return;
    }

    setAnimation("Talk");
    //setFacialExpression(message.facialExpression);
    //setLipsync(message.lipsync);
    const audio = new Audio("data:audio/mp3;base64," + message.audio);
    audio.play();
    setAudio(audio);
    audio.onended = onMessagePlayed;
  }, [message]);

  useEffect(() => {
    actions[animation]?.reset().fadeIn(0.32).play();
    return () => actions[animation]?.fadeOut(0.32);
  }, [animation]);

  //   useEffect(() => {
  //     try {
  //       actions[animation]
  //         .reset()
  //         .fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5)
  //         .play();
  //       return () => actions[animation]?.fadeOut(0.5);
  //     } catch (error) {
  //       console.error("Errore durante l'esecuzione dell'animazione:", error);
  //     }
  //   }, [animation, actions, mixer.stats.actions.inUse]);

  useEffect(() => {
    clone.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  }, []);

  useEffect(() => {
    actions[animation].reset().fadeIn(0.32).play();
    return () => actions[animation]?.fadeOut(0.32);
  }, [animation]);

  useFrame((state) => {
    const hips = avatar.current.getObjectByName("Hips");
    hips.position.set(0, hips.position.y, 0);
    if (path?.length && group.current.position.distanceTo(path[0]) > 0.1) {
      const direction = group.current.position
        .clone()
        .sub(path[0])
        .normalize()
        .multiplyScalar(MOVEMENT_SPEED);
      group.current.position.sub(direction);
      group.current.lookAt(path[0]);
    }
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={clone} ref={avatar} />
    </group>
  );
}

// useGLTF.preload("/models/Animated Woman.glb");
useGLTF.preload("/animations/M_Walk_001.glb");
useGLTF.preload("/animations/M_Standing_Idle_001.glb");
useGLTF.preload("/animations/M_Dances_001.glb");
