import * as THREE from "three";
import { loadGltf } from "../utils/loaders";
import { FaceLandmarkerResult } from "@mediapipe/tasks-vision";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";


class AvatarManager {
   static instance: AvatarManager = new AvatarManager();
  isModelLoaded = false;
  gltf = null;

   constructor() {
    this.scene = new THREE.Scene();
  }

  static getInstance(): AvatarManager {
    return AvatarManager.instance;
  }

  getScene = () => {
    return this.scene;
  };

  getModel = () => {
    console.log(this.gltf)
    return this.gltf;
  };


  loadModel = async (url: string) => {
    try {
    this.isModelLoaded = false;
    if (this.scene.children.length === 1) { 
      this.scene.children[0].removeFromParent();
    }
    const gltf = await loadGltf(url);
    gltf.scene.traverse((obj) => (obj.frustumCulled = false));
    this.scene.add(gltf.scene);
    this.gltf = gltf;
    console.log("animation",this.scene.animations);
    
  } catch (error) {
    console.error("Error loading model:", error);
    // Aggiungi qui la logica per gestire l'errore, ad esempio mostrare un messaggio all'utente
  }
  
   };

  };

export default AvatarManager;
